import React, { useEffect, useRef } from "react";
import { Box, Flex, Text, Heading, useBreakpointValue } from "@chakra-ui/react";

import { fontSizes } from "utils/custom-theme";

const OurNames = () => {
  const headingStyles = {
    color: "#fff",
    fontSize: useBreakpointValue({ base: "48px", sm: "68px", md: "80px" }),
    fontWeight: "700",
  };

  const initialStyles = {
    color: "#fff",
    fontSize: fontSizes.xlt,
    fontWeight: "400",
    letterSpacing: "2px",
  };

  const dateLocStyles = {
    fontSize: useBreakpointValue({
      base: fontSizes.mdt,
      sm: fontSizes.lgt,
      md: fontSizes.xlt,
    }),
    fontWeight: useBreakpointValue({ base: "500", sm: "400" }),
    color: "#fff",
    letterSpacing: "2px",
  };

  useEffect(() => {
    containerRef.current.classList.add("fade-in-delayed");
  }, []);

  const containerRef = useRef();

  return (
    <Flex
      ref={containerRef}
      className="names-container"
      opacity={0}
      mx="auto"
      p="8px"
      w="min-content"
      flexDirection="column"
      alignItems="center"
      borderRadius="2px"
    >
      <Flex alignItems="center" justifyContent="center" w="100%">
        <Text {...initialStyles}>M</Text>
        <Box h="2px" bg="#fff" w="140px" mx="8px" />
        <Text {...initialStyles}>B</Text>
      </Flex>

      <Flex alignItems="center" justifyContent="center">
        <Heading {...headingStyles}>Mairin</Heading>
        <Heading {...headingStyles} mx="16px">
          &
        </Heading>
        <Heading {...headingStyles}>Brian</Heading>
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="center"
        mt="0px"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Text {...dateLocStyles}>AUGUST 13, 2023</Text>
        <Text ml={{ md: "1rem" }} {...dateLocStyles}>
        MARKREE CASTLE
        </Text>
      </Flex>
    </Flex>
  );
};

export default OurNames;
