export const NAV_ITEMS = [
  {
    label: "Home",
    href: "/",
    section: "top",
  },
  {
    label: "RSVP",
    href: "/rsvp",
    section: "rsvp",
  },
  {
    label: "Travel",
    href: "/travel",
    section: "travel",
  },
  //{
  //  label: "Room Request",
  //  href: "/room",
  //  section: "room",
  //},
  {
    label: "To Do/To Eat",
    href: "/thingstodo",
    section: "activities",
  },
  // {
  //   label: "Wedding Party",
  //   href: "/weddingparty",
  //   section: "weddingParty",
  // },
  {
    label: "Gallery",
    href: "/gallery",
    section: "gallery",
  },
];

export const MAX_WIDTHS = () => ({
  base: "420px",
  sm: "540px",
  md: "720px",
  lg: "900px",
});

export const ACTIVITIES = {
  lunchDinner: [
    {
      name: "Hooked",
      url: "https://hookedsligo.ie/",
      priceLevel: 2,
    },
    {
      name: "Honestly Farm Kitchen",
      url: "https://honestlykitchen.ie/honestly-farm-kitchen-strandhill-2/",
      priceLevel: 2,
    },
    {
      name: "Eala Bhan",
      url: "https://www.ealabhan.ie/",
      priceLevel: 3,
    },
    {
      name: "The Venue",
      url: "https://venuestrandhill.ie/",
      priceLevel: 3,
    },
  ],
  breakfast: [
    {
      name: "Nook",
      url: "https://www.nookcollooney.com/",
      priceLevel: 1,
    },
    {
      name: "Osta",
      url: "https://osta.ie/",
      priceLevel: 1,
    },
    {
      name: "Hooked",
      url: "https://hookedsligo.ie/",
      priceLevel: 2,
    },
  ],
  coffeeTea: [
    {
      name: "Nook",
      url: "https://www.nookcollooney.com/",
      priceLevel: 1,
    },
    {
      name: "St Honorè",
      url: "http://sthonore.ie/",
      priceLevel: 1,
    },
  ],
  activities: [
    {
      name: "The Shed Distillery",
      url: "http://thesheddistillery.com/",
      priceLevel: 2,
    },
    {
      name: "Eagles Flying",
      url: "https://eaglesflying.com/",
      priceLevel: 1,
    },
    {
      name: "Funny Bones",
      url: "http://www.funnybones.ie/",
      priceLevel: 1,
    },
    {
      name: "Strandhill Golf Club",
      url: "http://www.strandhillgolfclub.com/",
      priceLevel: 1,
    },
    {
      name: "Benbulben Forest Walk",
      url: "https://www.google.com/maps/dir//Benbulben+Forest+Walk,+Gortarowey,+County+Sligo/@54.3617843,-8.5723684,12z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x485ee77c255a5d7b:0x249c6367e3649140!2m2!1d-8.502329!2d54.3618046!3e3",
      priceLevel: 0,
    },
    {
      name: "Knocknarea",
      url: "https://www.google.com/maps/dir//Knocknarea,+Knocknarea+South,+County+Sligo/@54.2587439,-8.6445349,12z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x485ec3a7356579bb:0x44b92096ebf643e3!2m2!1d-8.5744955!2d54.2587642!3e3",
      priceLevel: 0,
    },
    {
      name: "Strandhill Beach",
      url: "https://www.google.com/maps/dir//Strandhill+Beach,+Shore+Rd,+Carrowbunnaun,+Strandhill,+Co.+Sligo/@54.2699404,-8.646257,14z/data=!4m22!1m12!3m11!1s0x485ec364af525edf:0x91fe6352adadb717!2sStrandhill+Beach!3b1!5m4!1s2023-03-13!2i3!4m1!1i2!8m2!3d54.2699462!4d-8.6103788!4m8!1m0!1m5!1m1!1s0x485ec364af525edf:0x91fe6352adadb717!2m2!1d-8.6103789!2d54.2699462!3e3",
      priceLevel: 0,
    },
    {
      name: "Killaspugbrone Loop Walk",
      url: "https://www.google.ie/maps/dir//79HV%2BP4+Killaspugbrone+Loop+Walk,+Killaspugbrone,+Strandhill,+Co.+Sligo/@54.2792534,-8.6093337,17z/data=!4m16!1m6!3m5!1s0x485ec37493860bcb:0xec29bfd8de04c79a!2sKillaspugbrone+Loop+Walk!8m2!3d54.2792534!4d-8.607145!4m8!1m0!1m5!1m1!1s0x485ec37493860bcb:0xec29bfd8de04c79a!2m2!1d-8.607145!2d54.2792534!3e3",
      priceLevel: 0,
    },
    {
      name: "Rosses Point Beach",
      url: "https://www.google.com/maps/dir//Rosses+Point+Beach,+beach,+Rosses+Point,+County+Sligo/@54.3089162,-8.641871,12z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x485ec2d4c42c5255:0xb95d74815c1d317d!2m2!1d-8.5718316!2d54.3089365!3e3",
      priceLevel: 0,
    },
  ],
  cocktailBars: [
    {
      name: "Thomas Connollys",
      url: "https://www.thomasconnollysligo.com/",
      priceLevel: 1,
    },
    {
      name: "McLynns Bar",
      url: "https://www.mclynnsbar.com/",
      priceLevel: 1,
    },
    {
      name: "Hargadons Bar",
      url: "https://www.hargadons.com/",
      priceLevel: 1,
    },
    {
      name: "Lillies Cocktail Bar",
      url: "https://www.lilliescocktailbarsligo.ie/",
      priceLevel: 2,
    },
  ],
};

export const scheduleItems = {
  friday: [],
  saturday: [
    {
      heading: "Arrival / Check-in",
      time: "2.00pm",
      locationName: "Markree Castle",
      locationMapLink: "https://www.google.com/maps/place/Markree+Castle/@54.1743623,-8.4613887,15z/data=!4m2!3m1!1s0x0:0x6572031f912fe0cb?sa=X&ved=2ahUKEwj129vMiKf8AhVZFcAKHZvCBigQ_BJ6BQiGARAH",
    },
    {
      heading: "Ceremony",
      time: "2.30pm - 3.30pm",
      locationName: "Markree Castle",
      locationMapLink: "https://www.google.com/maps/place/Markree+Castle/@54.1743623,-8.4613887,15z/data=!4m2!3m1!1s0x0:0x6572031f912fe0cb?sa=X&ved=2ahUKEwj129vMiKf8AhVZFcAKHZvCBigQ_BJ6BQiGARAH",
    },
    {
      heading: "Reception",
      time: "3.45pm - 5pm",
      locationName: "Markree Castle",
      locationMapLink: "https://www.google.com/maps/place/Markree+Castle/@54.1743623,-8.4613887,15z/data=!4m2!3m1!1s0x0:0x6572031f912fe0cb?sa=X&ved=2ahUKEwj129vMiKf8AhVZFcAKHZvCBigQ_BJ6BQiGARAH",
    },
    {
      heading: "Dinner & Speeches",
      time: "5pm - 7pm",
      locationName: "Markree Castle",
      locationMapLink: "https://www.google.com/maps/place/Markree+Castle/@54.1743623,-8.4613887,15z/data=!4m2!3m1!1s0x0:0x6572031f912fe0cb?sa=X&ved=2ahUKEwj129vMiKf8AhVZFcAKHZvCBigQ_BJ6BQiGARAH",
    },
    {
      heading: "Music & Dancing",
      time: "9pm - Late",
      locationName: "Markree Castle",
      locationMapLink: "https://www.google.com/maps/place/Markree+Castle/@54.1743623,-8.4613887,15z/data=!4m2!3m1!1s0x0:0x6572031f912fe0cb?sa=X&ved=2ahUKEwj129vMiKf8AhVZFcAKHZvCBigQ_BJ6BQiGARAH",
    },
  ],
};
